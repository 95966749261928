<template>
        <div :class="!$vuetify.breakpoint.mobile?'login':''">
    <v-container :fill-height="!$vuetify.breakpoint.mobile">
        <v-row justify="center" align="center" style="height: 90%" class="text-center">
          <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3" class="d-flex justify-center align-center">
            <div
              style="
                background: rgba(141, 141, 141, 0.1) !important;
                background-color: rgba(141, 141, 141, 0.1) !important;
                border-radius: 16px;
                box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                backdrop-filter: blur(6.9px);
                -webkit-backdrop-filter: blur(6.9px);
                border: 1px solid rgba(255, 255, 255, 0.27);
                z-index: 10;
                max-width: 95%;
              "
            >
              <v-img
                max-width="60%"
                max-height="180px"
                contain
                :src="tenantBrand.logoUrl.landingLight"
                class="mt-4 mb-1 mx-auto"
              />
              <v-col
                cols="12"
                class="text-center my-0 py-0"
                v-if="tenantBrand.label == 'LØGLIVE'"
              >
                <div class="logo-text">
                  <span>-</span>
                  <span class="loading-text-words">L</span>
                  <span class="loading-text-words">Ø</span>
                  <span class="loading-text-words">G</span>
                  <span class="loading-text-words">L</span>
                  <span class="loading-text-words">I</span>
                  <span class="loading-text-words">V</span>
                  <span class="loading-text-words">E</span>
                  <span class="loading-text-words">-</span>
                </div>
              </v-col>
              <v-card-subtitle class="my-2">
                Please enter your new password for your account.
              </v-card-subtitle>
              <v-card-text>
                <v-row>
                <v-col cols="12" sm="12" class="py-0 my-0">
                  <v-text-field
                    :rules="[rules.counter]"
                    ref="password"
                    dense
                    label="Password"
                    outlined
                    prepend-inner-icon="lock"
                    v-model="model.password"
                    :type="passwordType"
                    :append-icon="
                      passwordType == 'text' ? 'visibility' : 'visibility_off'
                    "
                    @click:append="changePasswordVisibility"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" class="py-0 my-0">
                  <v-text-field
                    ref="confirmPassword"
                    dense
                    label="Confirm Password"
                    :disabled="!model.password"
                    outlined
                    prepend-inner-icon="lock"
                    v-model="model.confirmPassword"
                    :type="passwordType"
                    :append-icon="
                      passwordType == 'text' ? 'visibility' : 'visibility_off'
                    "
                    @click:append="changePasswordVisibility"
                  ></v-text-field>
                </v-col>
              </v-row>
              <div v-if="!loading" class="my-0 py-0">
                  <v-row justify="center">
                    <v-col cols="10" sm="10" md="8" class="text-center pb-1 mb-1 mt-0 pt-0">
                      <v-btn color="primary" :disabled="
                        !model.password ||
                        !model.confirmPassword ||
                        !checkPassword()"
                        class="white--text login-button" @click="submitPasswordChange" :loading="loading">{{ !loading ? "Submit" : ""
                        }}</v-btn>
                    </v-col>
                  </v-row>

                  <br />
                  <a
                  class="forgot-password"
                  @click="$router.push({ path: '/login' })"
                  style="cursor: pointer"
                  >Already got an account?</a
                >
                </div>
                <div v-else style="height: 100px; padding-top: 0; margin-top: 0">
                </div>
                </v-card-text>
            </div>
            </v-col>
            </v-row>
            </v-container>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    data: ()=>({
        loading: false,
        model: {
        emailAddress: "",
        password: "",
      },
      passwordType: "password",
      rules: {
        counter: (value) => value && value.length >= 8 || "Min 8 characters",
        matchPassword: (value) =>
        (this.model && value == this.model.password) || "Passwords do not match",
        password: [
          {
            required: true,
            message: "Password is required",
            trigger: "blur",
          },
          {
            min: 8,
            message: "Password length should be at least 8 characters",
            trigger: "blur",
          },
        ],
        // confirmPassword: [{validator: checkPassword(this.model), message: 'Passwords need to match', trigger:'blur' }]
      },
    }),
    watch: {
      "$route.query.hash": {
        immediate: true,
        async handler(val) {
          console.log(val)
        },
      },
    },
    methods: {
      changePasswordVisibility() {
        if (this.passwordType == "text") {
          this.passwordType = "password";
        } else {
          this.passwordType = "text";
        }
      },
      checkPassword() {
        return this.model.password === this.model.confirmPassword;
      },
      async submitPasswordChange(){
          try {
              this.loading = true
              let result = await this.$API.submitPasswordChange(this.model, {
                  params: {
                      hash: this.$route.query.hash
                  }
              })
              if(result && result.code == 200){
                  this.$message.success("Password changed successfully")
                  this.$router.push({path: '/'})
              } else {
                  this.$message.error(result.message)
              }
              this.loading = false
          } catch (error) {
              this.$message.error(error.message)
              this.loading = false
          }
      },
    },
    computed: {
      ...mapGetters(['tenantBrand'])
    }
}
</script>

<style scoped>
.login {
  flex: 1;
  height: 90vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: slategray; */
}

.login-button {
  width: 100%;
  margin-top: 10px;
}

.login-form {
  width: 100%;
  align-items: center;
  margin: 0;
  padding: 10px;
}

.forgot-password {
  font-size: 14px;
  margin-top: 10px;
}
</style>
<style lang="scss">
$teal: green;

.el-button--primary {
  background: $teal;
  border-color: $teal;

  &:hover,
  &.active,
  &:focus {
    background: lighten($teal, 7);
    border-color: lighten($teal, 7);
  }

  &:disabled {
    background: grey;
    color: white;
  }
}

.login .el-input__inner:hover {
  border-color: $teal;
}

.login .el-input__prefix {
  background: rgb(238, 237, 234);
  left: 0;
  height: calc(100% - 2px);
  left: 1px;
  top: 1px;
  border-radius: 3px;

  .el-input__icon {
    width: 30px;
  }
}

.login .el-input input {
  padding-left: 35px;
}

.login .el-card {
  padding-top: 0;
  padding-bottom: 30px;
}

a {
  color: $teal;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    color: lighten($teal, 7);
  }
}

.login .el-card {
  border-radius: 25px;
  min-width: 70%;
  max-width: 350px;
  display: flex;
  justify-content: center;
}
</style>
